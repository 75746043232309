import React from "react"
import Layout from "./../../../components/layouts/Request"
import Seo from "./../../../components/layouts/Seo"
import RequestLeftCol from "./../../../components/layouts/partials/pages/request/RequestLeftCol"
import RequestRightCol from "./../../../components/layouts/partials/pages/request/RequestRightCol"
import Wizard from "./../../../components/forms/Wizard"
import FormMainData from "./../../../components/layouts/partials/pages/request/MainData"
import CheckboxCard from "./../../../components/forms/CheckboxCard"

export default function Page() {
  const svg = {
    rente: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="inline"
        width={64}
        height={64}
        viewBox="0 0 24 24"
        strokeWidth="1"
        stroke="#000"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" />
        <path d="M4 12v-3a3 3 0 0 1 3 -3h13m-3 -3l3 3l-3 3" />
        <path d="M20 12v3a3 3 0 0 1 -3 3h-13m3 3l-3-3l3-3" />
      </svg>
    ),
    kapital: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="inline"
        width={64}
        height={64}
        viewBox="0 0 24 24"
        strokeWidth="1"
        stroke="#000"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" />
        <path d="M17 8v-3a1 1 0 0 0 -1 -1h-10a2 2 0 0 0 0 4h12a1 1 0 0 1 1 1v3m0 4v3a1 1 0 0 1 -1 1h-12a2 2 0 0 1 -2 -2v-12" />
        <path d="M20 12v4h-4a2 2 0 0 1 0 -4h4" />
      </svg>
    ),
    feordrung: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="inline"
        width={64}
        height={64}
        viewBox="0 0 24 24"
        strokeWidth="1"
        stroke="#000"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" />
        <line x1={3} y1={21} x2={21} y2={21} />
        <line x1={3} y1={10} x2={21} y2={10} />
        <polyline points="5 6 12 3 19 6" />
        <line x1={4} y1={10} x2={4} y2={21} />
        <line x1={20} y1={10} x2={20} y2={21} />
        <line x1={8} y1={14} x2={8} y2={17} />
        <line x1={12} y1={14} x2={12} y2={17} />
        <line x1={16} y1={14} x2={16} y2={17} />
      </svg>
    ),
    flex: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="inline"
        width={64}
        height={64}
        viewBox="0 0 24 24"
        strokeWidth="1"
        stroke="#000"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" />
        <circle cx={6} cy={10} r={2} />
        <line x1={6} y1={4} x2={6} y2={8} />
        <line x1={6} y1={12} x2={6} y2={20} />
        <circle cx={12} cy={16} r={2} />
        <line x1={12} y1={4} x2={12} y2={14} />
        <line x1={12} y1={18} x2={12} y2={20} />
        <circle cx={18} cy={7} r={2} />
        <line x1={18} y1={4} x2={18} y2={5} />
        <line x1={18} y1={9} x2={18} y2={20} />
      </svg>
    ),
  }

  return (
    <Layout>
      <Seo
        title="Altersvorsorge anfragen"
        description="Wir finden die passende Altersvorsorge für Dich."
        path="/versicherungen/altersvorsorge/anfragen/"
      />
      <section>
        <div className="flex flex-wrap">
          <div className="w-full lg:w-8/12 lg:order-2 max-w-screen-lg p-8 lg:p-16">
            <RequestRightCol>
              <h1 className="text-2xl md:text-3xl lg:text-4xl font-bold">
                Altersvorsorge anfragen
              </h1>
              <form
                action="https://formbackend.dierda.de/anfrage.php"
                method="POST"
              >
                <input type="hidden" name="Anfrage" value="Altersvorsorge" />

                <Wizard>
                  <div>
                    <div className="mt-8 mb-4">
                      <h3 className="text-lg leading-6 font-bold text-gray-900">
                        Was ist Dir wichtig?
                      </h3>
                      <p className="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
                        Klicke kurz an, was Du von Deiner Altersvorsorge
                        erwartest. Diese Informationen sind wichtig, damit wir
                        ungefähr wissen, was Du benötigst.
                      </p>
                    </div>
                    <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-4 mb-6">
                      <CheckboxCard
                        label="Sichere Rente im Alter"
                        name="Mir ist wichtig"
                        value="Rente"
                        id="rente"
                        svg={svg.rente}
                      />

                      <CheckboxCard
                        label="Kapital im Alter"
                        name="Mir ist wichtig"
                        value="Kapital"
                        id="kapital"
                        svg={svg.kapital}
                      />

                      <CheckboxCard
                        label="Staatliche Förderung"
                        name="Mir ist wichtig"
                        value="Staatliche Förderung"
                        id="foerderung"
                        svg={svg.feordrung}
                      />

                      <CheckboxCard
                        label="Flexibilität"
                        name="Mir ist wichtig"
                        value="Flexibilität"
                        id="flexibilitaet"
                        svg={svg.flex}
                      />
                    </div>
                  </div>
                  <FormMainData />
                </Wizard>
              </form>
            </RequestRightCol>
          </div>
          <div className="w-full lg:w-4/12 lg:order-1 bg-gray-100 p-8 lg:p-16 ">
            <RequestLeftCol />
          </div>
        </div>
      </section>
    </Layout>
  )
}
